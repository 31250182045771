import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Swiper from "swiper";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import "swiper/css/effect-fade";

import {
  Navigation,
  Pagination,
  EffectFade,
  EffectCreative,
} from "swiper/modules";

Swiper.use([Navigation, Pagination, EffectFade, EffectCreative]);

let isMobile = false;
if (window.innerWidth < 740) {
  isMobile = true;
}
gsap.registerPlugin(ScrollTrigger);

const Scroll = new (function () {
  let sections;
  let page;
  let main;
  let scrollTrigger;
  let scrollBg;
  let tl;
  let win;

  // Init
  this.init = () => {
    sections = document.querySelectorAll(".screen");
    page = document.querySelector(".main");
    main = document.querySelector(".main");
    win = {
      w: window.innerWidth,
      h: window.innerHeight,
    };

    this.setupTimeline();
    this.setupScrollTrigger();
    window.addEventListener("resize", this.onResize);
  };

  let screenMorning = document.querySelector(".screen__morning");
  let screenDay = document.querySelector(".screen__day");
  let screenEvening = document.querySelector(".screen__evening");
  let screenNight = document.querySelector(".screen_night");

  // Setup ScrollTrigger
  this.setupScrollTrigger = () => {
    // sections.forEach((section) => {
    //   section.style.height = window.innerHeight + "px";
    // });

    page.style.height = this.getTotalScroll() + win.h + "px";
    let frameCount = 4;

    let endHeight = this.getTotalScroll() + win.h;
    scrollTrigger = ScrollTrigger.create({
      id: "mainScroll",
      // trigger: "main",
      animation: tl,
      pin: true,
      scrub: true,
      onRefreshInit: () => {
        document.querySelector(".pixel").classList.add("loaded");
      },
      start: "top top",
      end: "+=" + document.querySelector(".main").offsetHeight,
    });
  };

  // Setup timeline
  this.setupTimeline = () => {
    tl = gsap.timeline();
    tl.addLabel("label-initial");

    sections.forEach((section, index) => {
      const nextSection = sections[index + 1];
      if (!nextSection) return;

      tl.to(nextSection, {
        y: -1 * nextSection.offsetHeight,
        duration: nextSection.offsetHeight,
        ease: "linear",
      }).addLabel(`label${index}`);

      tl.to(".screen__morning .screen__content", { yPercent: -100 });
    });
  };

  // On resize
  this.onResize = () => {
    win = {
      w: window.innerWidth,
      h: window.innerHeight,
    };

    this.reset();
  };

  // Reset
  this.reset = () => {
    if (typeof ScrollTrigger.getById("mainScroll") === "object") {
      ScrollTrigger.getById("mainScroll").kill();
    }

    if (typeof tl === "object") {
      tl.kill();
      tl.seek(0);
    }

    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.init();
  };

  // Get total scroll
  this.getTotalScroll = () => {
    let totalScroll = 0;
    sections.forEach((section) => {
      totalScroll += section.offsetHeight;
    });
    totalScroll -= win.h;
    return totalScroll;
  };
})();

document.addEventListener("DOMContentLoaded", () => {
  setTimeout(() => {
    Scroll.init();
  }, 1000);
});

// Высоты экранов
const morningHeight = document.querySelector(".screen__morning").offsetHeight;
const dayHeight = document.querySelector(".screen__day").offsetHeight;
const eveningHeight = document.querySelector(".screen__evening").offsetHeight;
const nightHeight = document.querySelector(".screen__night").offsetHeight;

const topTop = '"top top"';

// Общая анимация с использованием GSAP
// Функция для анимации облаков
function createCloudTimeline(
  trigger,
  startOffset,
  endOffset,
  to,
  x,
  y,
  secondX,
  secondY,
  opacity
) {
  // const imgFromElement = document.querySelector(imgFrom);
  // const imgToElement = document.querySelector(imgTo);

  // if (!imgFromElement || !imgToElement) {
  //   console.warn(`Элемент отсутствует: ${imgFrom} или ${imgTo}`);
  //   return null;
  // }
  if (secondX && secondY) {
    return gsap
      .timeline({
        scrollTrigger: {
          trigger,
          start: startOffset,
          end: `+=${endOffset}`,
          scrub: true,
        },
      })
      .addLabel("start")
      .to(to, { x: x, y: y }) // Анимация появления
      .addLabel("start")
      .to(to, { x: x, y: y, opacity: opacity }) // Анимация исчезновения
      .addLabel("start");
  } else {
    return gsap
      .timeline({
        scrollTrigger: {
          trigger,
          start: startOffset,
          end: `+=${endOffset}`,
          scrub: true,
        },
      })
      .addLabel("start")
      .to(to, { x: x, y: y });
  }
}
// Облака
//первое облако
createCloudTimeline(
  ".screen__morning",
  topTop,
  morningHeight,
  ".clouds__img-block_1",
  -400,
  0
);
createCloudTimeline(
  ".screen__day",
  dayHeight,
  dayHeight,
  ".clouds__img-block_1",
  -1150,
  -100
);
createCloudTimeline(
  ".screen__evening",
  eveningHeight * 2,
  eveningHeight,
  ".clouds__img-block_1",
  -1200,
  -50
);
createCloudTimeline(
  ".screen__night",
  nightHeight * 2.7,
  nightHeight,
  ".clouds__img-block_1",
  -1200,
  -50,
  -1200,
  -500,
  0
);

//второе облако
createCloudTimeline(
  ".screen__morning",
  topTop,
  morningHeight,
  ".clouds__img-block_2",
  -250,
  0
);
createCloudTimeline(
  ".screen__day",
  dayHeight,
  dayHeight,
  ".clouds__img-block_2",
  -620,
  -20
);
createCloudTimeline(
  ".screen__evening",
  eveningHeight * 2,
  eveningHeight,
  ".clouds__img-block_2",
  -820,
  -0
);
createCloudTimeline(
  ".screen__night",
  nightHeight * 2.7,
  nightHeight,
  ".clouds__img-block_2",
  -820,
  0,
  -820,
  -500,
  0
);

//третье облако
createCloudTimeline(
  ".screen__morning",
  topTop,
  morningHeight,
  ".clouds__img-block_3",
  250,
  0
);
createCloudTimeline(
  ".screen__day",
  dayHeight,
  dayHeight,
  ".clouds__img-block_3",
  700,
  0
);
createCloudTimeline(
  ".screen__evening",
  eveningHeight * 2,
  eveningHeight,
  ".clouds__img-block_3",
  900,
  30
);
createCloudTimeline(
  ".screen__night",
  nightHeight * 2.7,
  nightHeight,
  ".clouds__img-block_3",
  900,
  30,
  900,
  -500,
  0
);

//четвёртрое облако
createCloudTimeline(
  ".screen__morning",
  topTop,
  morningHeight,
  ".clouds__img-block_4",
  525,
  -200
);
createCloudTimeline(
  ".screen__day",
  dayHeight,
  dayHeight,
  ".clouds__img-block_4",
  1300,
  -100
);
createCloudTimeline(
  ".screen__evening",
  eveningHeight * 2,
  eveningHeight,
  ".clouds__img-block_4",
  1400,
  -200
);
createCloudTimeline(
  ".screen__night",
  nightHeight * 2.7,
  nightHeight,
  ".clouds__img-block_4",
  1400,
  -200,
  1400,
  -800,
  0
);

// Солнце
createCloudTimeline(
  ".screen__morning",
  topTop,
  morningHeight,
  ".clouds__img-block_sun",
  600,
  -600
);
createCloudTimeline(
  ".screen__day",
  dayHeight,
  dayHeight,
  ".clouds__img-block_sun",
  1230,
  -570
);
createCloudTimeline(
  ".screen__evening",
  eveningHeight * 2,
  eveningHeight,
  ".clouds__img-block_sun",
  1920,
  250
);
createCloudTimeline(
  ".screen__night",
  nightHeight * 2.7,
  nightHeight,
  ".clouds__img-block_sun",
  1920,
  250,
  1920,
  -500,
  0
);

// Изменение фона
function changeBackground(
  trigger,
  startOffset,
  endOffset,
  colorFrom,
  colorTo,
  opacityFrom,
  opacityTo
) {
  return gsap
    .timeline({
      scrollTrigger: {
        trigger,
        start: startOffset,
        end: `+=${endOffset}`,
        // end: `"+=" + ${endOffset}`,
        // end: "bottom bottom",
        scrub: true,
        // duration: 1,
        // markers: true,
      },
    })
    .to(colorFrom, {
      opacity: 1,
    })
    .to(colorTo, {
      opacity: 1,
    });
}

// Фоновые переходы
changeBackground(
  ".screen__morning",
  topTop,
  morningHeight,
  ".background__morning",
  ".background__day",
  0,
  1
);
changeBackground(
  ".screen__day",
  dayHeight,
  dayHeight,
  ".background__evening",
  ".background__day",

  0,
  1
);
changeBackground(
  ".screen__evening",
  eveningHeight * 2,
  eveningHeight,
  ".background__night",
  ".background__evening",
  0,
  1
);
// changeBackground(
//   ".screen__night",
//   topTop,
//   nightHeight,
//   ".background__night",
//   ".background__night",
//   0,
//   1
// );

//цвет облака 1
changeBackground(
  ".screen__morning",
  topTop,
  morningHeight,
  ".clouds__img-morning-1",
  ".clouds__img-day-1",
  0,
  1
);
changeBackground(
  ".screen__day",
  dayHeight,
  dayHeight,
  ".clouds__img-day-1",
  ".clouds__img-evening-1",

  0,
  1
);
changeBackground(
  ".screen__evening",
  eveningHeight * 2,
  eveningHeight,

  ".clouds__img-night-1",
  ".clouds__img-evening-1",
  1,
  0
);
// changeBackground(
//   ".screen__night",
//   nightHeight,
//   nightHeight,
//   ".clouds__img-night-1",
//   ".clouds__img-night-1",
//   0,
//   1
// );

//цвет облака 2
changeBackground(
  ".screen__morning",
  topTop,
  morningHeight,
  ".clouds__img-morning-2",
  ".clouds__img-day-2",
  0,
  1
);
changeBackground(
  ".screen__day",
  dayHeight,
  dayHeight,
  ".clouds__img-day-2",
  ".clouds__img-evening-2",

  0,
  1
);
changeBackground(
  ".screen__evening",
  eveningHeight * 2,
  eveningHeight,

  ".clouds__img-night-2",
  ".clouds__img-evening-2",
  1,
  0
);

//цвет облака 3
changeBackground(
  ".screen__morning",
  topTop,
  morningHeight,
  ".clouds__img-morning-3",
  ".clouds__img-day-3",
  0,
  1
);
changeBackground(
  ".screen__day",
  dayHeight,
  dayHeight,
  ".clouds__img-day-3",
  ".clouds__img-evening-3",

  0,
  1
);
changeBackground(
  ".screen__evening",
  eveningHeight * 2,
  eveningHeight,

  ".clouds__img-night-3",
  ".clouds__img-evening-3",
  1,
  0
);

//цвет облака 4
changeBackground(
  ".screen__morning",
  topTop,
  morningHeight,
  ".clouds__img-morning-4",
  ".clouds__img-day-4",
  0,
  1
);
changeBackground(
  ".screen__day",
  dayHeight,
  dayHeight,
  ".clouds__img-day-4",
  ".clouds__img-evening-4",

  0,
  1
);
changeBackground(
  ".screen__evening",
  eveningHeight * 2,
  eveningHeight,

  ".clouds__img-night-4",
  ".clouds__img-evening-4",
  1,
  0
);

gsap
  .timeline({
    scrollTrigger: {
      trigger: ".screen__morning",
      start: "top top",
      end: `+=${morningHeight / 2}`,
      scrub: true,
    },
  })
  .addLabel("start")
  .to(".screen__morning .screen__content", { opacity: 0, y: -400 })
  .addLabel("end");

// День
gsap
  .timeline({
    scrollTrigger: {
      trigger: ".screen__day",
      start: "top top",
      end: `+=${dayHeight * 3}`,
      scrub: 1,
    },
  })
  .addLabel("start")
  .from(".screen__day .screen__content", { y: 0, duration: 2 })
  .to(".screen__day .screen__content", { y: -950, opacity: 0, duration: 2 })
  .addLabel("end");

// Вечер
// gsap
//   .timeline({
//     scrollTrigger: {
//       trigger: ".screen__evening",
//       start: eveningHeight,
//       end: `+=${eveningHeight * 1.8}`,
//       scrub: true,
//       // pin: true,
//     },
//   })
//   .addLabel("start")
//   .from(".screen__evening .screen__content", { y: 400, opacity: 0 })
//   .to(".screen__evening .screen__content", { y: -700, opacity: 1 })
//   .addLabel("end");

// Ночь
// gsap.timeline({
//   scrollTrigger: {
//     trigger: ".screen__night",
//     start: `${document.querySelector(".screen__night").offsetHeight - 500}`,
//     end: `+=${document.querySelector(".screen__night").offsetHeight}`,
//     scrub: true,
//   },
// });

let tlBuilding = gsap.timeline({
  scrollTrigger: {
    trigger: ".building",
    // start: document.querySelector(".screen__night").offsetHeight,
    // end: "+=" + document.querySelector(".screen__night").offsetHeight,

    start: "bottom bottom",
    // start: "+=" + document.querySelector(".screen__evening").offsetHeight,
    end: "+=100",
    // end: "+=" + document.querySelector(".screen__night").offsetHeight,
    pin: true,
    scrub: 0.5,
    // markers: true,
    onEnter: () => {},
    onLeave: () => {},
  },
});
tlBuilding
  .addLabel("start")
  .from(".building", { opacity: 1, scale: 1 })
  .addLabel("end")
  .to(".building", { opacity: 0, scale: 1.1 })
  .addLabel("end");

let tlWindows = gsap.timeline({
  scrollTrigger: {
    trigger: ".building",
    // start: document.querySelector(".screen__night").offsetHeight,
    // end: "+=" + document.querySelector(".screen__night").offsetHeight,
    start: "bottom bottom",
    // start: document.querySelector(".screen__night").offsetHeight * 1.85,
    // start: "+=" + document.querySelector(".screen__evening").offsetHeight,
    end: "+=200",
    // pin: true,
    scrub: true,
    duration: 2,
    onEnter: () => {
      document.querySelector(".windows").classList.add("active");
    },
    onLeaveBack: () => {
      document.querySelector(".windows").classList.remove("active");
    },
    onLeave: () => {
      document.querySelector(".windows").classList.remove("active");
    },
    // markers: true,
  },
});
tlWindows
  .addLabel("start")
  .from(".windows", { opacity: 0, scale: 0.8, duration: 2 })
  .addLabel("end")
  .to(".windows", { opacity: 1, scale: 1, duration: 2 })
  .addLabel("end");

// Здание
// gsap
//   .timeline({
//     scrollTrigger: {
//       trigger: ".building",
//       start: "bottom bottom",
//       // end: "bottom bottom", // Увеличен диапазон
//       // end: "+=" + document.querySelector(".building").offsetHeight,
//       // scrub: 0.5,
//       end: "+=100", // Увеличен диапазон
//       scrub: true,
//       markers: true,
//       // pin: true,
//       // Убрали pin для улучшения производительности
//     },
//   })
//   .addLabel("start")
//   .from(".building", { opacity: 1, scale: 1, visibility: "visible" })
//   .to(".building", { opacity: 0, scale: 1.1, visibility: "hidden" })
//   .addLabel("end");

// // Окна
// const windowsSection = document.querySelector(".windows");
// let tlWindows = gsap.timeline({
//   scrollTrigger: {
//     trigger: ".building",
//     // start: "bottom bottom",
//     start: "bottom 200px",
//     // start: document.querySelector(".building").offsetHeight - 200,
//     end: "+=200", // Увеличен диапазон
//     scrub: true,

//     // onEnter: () => {
//     //   // if (!windows.classList.contains("active")) {
//     //   //   windows.classList.add("active");
//     //   // }
//     //   windowsSection.style.opacity = '1';
//     // },
//     // onLeaveBack: () => {
//     //   windowsSection.style.opacity = '0';
//     //   // if (windows.classList.contains("active")) {
//     //   //   windows.classList.remove("active");
//     //   // }
//     // },
//     // onEnter: () => {
//     //   windowsSection.style.opacity = '1';
//     //   // document.querySelector(".windows").classList.add("active");
//     // },
//     // onEnterBack: () => {
//     //   document.querySelector(".windows").classList.add("active");
//     // },
//     // onLeaveBack: () => {
//     //   document.querySelector(".windows").classList.remove("active");
//     // },
//     // onLeave: () => {
//     //   document.querySelector(".windows").classList.remove("active");
//     // },
//   },
// });
// tlWindows
//   .addLabel("start")
//   .from(".windows", { opacity: 0, scale: 0.7 })
//   .to(".windows", { opacity: 1, scale: 1 })
//   .addLabel("end");

// Проверяем наличие элементов
const page = document.querySelector("body");
const main = document.querySelector(".main");
const controls = document.querySelector(".controls");
const buildingLinks = document.querySelectorAll(".building__office-link");
const buildingSection = document.querySelector(".building");
const windowsSection = document.querySelector(".windows");
const persons = document.querySelectorAll(".building__person");
const slider = document.querySelector(".slider");
const sliderItems = document.querySelectorAll(".slider__item");
const modalClose = document.querySelectorAll(".modal__close");
const windows = document.querySelectorAll(".windows__image-link");
const employeeItems = document.querySelectorAll(".employees__item");
const modals = document.querySelectorAll(".modal");
const welldone = document.querySelector(".welldone");
const controlsElementZoom = document.querySelectorAll(
  ".controls__element-zoom"
);
const pixel = document.querySelector(".pixel");
const mainElementPlay = document.querySelector(
  ".main-element.controls__element-play"
);
const controlsElementPlay = document.querySelectorAll(
  ".controls__element-play"
);
const contentHidden = document.querySelectorAll(".content-hidden");
const totalSlides = 4;
let viewedSlides = new Set();

// Проверяем и инициализируем Swiper, если его класс существует

let buildingPersons;
const parentEl = document.querySelector(".slider .container").parentElement;

//счётчик слайдов
let doneSlides = false;

function openPopup() {
  gsap.to(".slider.modal", {
    duration: 0.5,
    opacity: 1,
    pointerEvents: "auto",
  });
  gsap.fromTo(
    ".slider.modal .container",
    { scale: 0.9 },
    { duration: 0.5, scale: 1, ease: "power2.out" }
  );
}

function closePopup() {
  gsap.to(".slider.modal", {
    duration: 0.5,
    opacity: 0,
    pointerEvents: "none",
  });
}

function setSliderHeights() {
  const sliderItem = document.querySelectorAll('.slider__item');
  

  // Проверяем ширину окна
  if (window.innerWidth <= 740) {
    sliderItem.forEach((sliderItem) => {
        const sliderContent = sliderItem.querySelector('.slider__content');
        const sliderInfo = sliderItem.querySelector('.slider__info');

          if (sliderItem) {
              const windowHeight = window.innerHeight;

              // Получаем размер шрифта для элемента
              const fontSize = parseFloat(getComputedStyle(sliderContent).fontSize);
              const additionalHeight = 5 * fontSize;

              const fontSizeInfo = parseFloat(getComputedStyle(sliderInfo).fontSize);
              const additionalHeightInfo = 20 * fontSizeInfo;

              // Устанавливаем высоту блока
              sliderContent.style.maxHeight = `${windowHeight + additionalHeight}px`;
              sliderInfo.style.maxHeight = `${windowHeight - additionalHeightInfo}px`;
          }
      });
  } 
}

// Устанавливаем высоту при загрузке страницы
setSliderHeights();

// Обновляем высоту при изменении размера окна
window.addEventListener('resize', setSliderHeights);

function incrementCounter(slideNumber) {
  if (viewedSlides.size === totalSlides) {
    doneSlides = false;
    return; // Завершаем функцию, если все слайды уже просмотрены
  }

  viewedSlides.add(slideNumber);

  if (viewedSlides.size === totalSlides) {
    doneSlides = true;
    return;
  }
}

if (document.querySelector(".building__persons-wrapper")) {
  const buildingOffice = document.querySelector(".building__office");
  buildingPersons = new Swiper(".building__persons-wrapper", {
    // slidesPerView: 4,
    centeredSlides: true,
    // spaceBetween: 16,
    loop: true,
    autoHeight: true,
    // effect: "fade",
    navigation: {
      nextEl: ".building__persons .swiper-button-next",
      prevEl: ".building__persons .swiper-button-prev",
    },
    pagination: {
      el: ".building__persons .swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1.4,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1.4,
      },
      // when window width is >= 640px
      740: {
        slidesPerView: 4,
        centeredSlides: false,
        spaceBetween: 32,
      },
    },
    on: {
      slideChange: function () {
        // console.log("Slide changed. Swiper realIndex:", this.realIndex);
        if (this.realIndex > 3 && this.realIndex < 8) {
          buildingOffice.classList.add("center");
          buildingOffice.classList.remove("left");
          buildingOffice.classList.remove("right");
        } else if (this.realIndex > 7) {
          buildingOffice.classList.add("right");
          buildingOffice.classList.remove("center");
          buildingOffice.classList.remove("left");
        } else {
          buildingOffice.classList.add("left");
          buildingOffice.classList.remove("center");
          buildingOffice.classList.remove("right");
        }
      },
    },
  });
}

buildingPersons = new Swiper(".windows__wrapper", {
  // slidesPerView: 4,
  centeredSlides: true,
  // spaceBetween: 16,
  loop: true,
  autoHeight: true,
  // effect: "fade",
  navigation: {
    nextEl: ".windows .swiper-button-next",
    prevEl: ".windows .swiper-button-prev",
  },
  pagination: {
    el: ".windows .swiper-pagination",
    clickable: true,
  },
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 1,
    },
    // when window width is >= 640px
    740: {
      slidesPerView: 1,
      centeredSlides: false,
      spaceBetween: 32,
    },
  },
});

// Обработка кликов на "persons"
if (persons.length) {
  persons.forEach((person, index) => {
    person.addEventListener("click", () => {
      if (!slider || !swiper) return;

      slider.classList.add("show");
      openPopup();
      windowsSection?.classList.add("hidden");
      page.style.overflow = "hidden";
      controls?.classList.add("hide");
      main?.classList.remove("fixed-height");
      buildingSection?.classList.add("hide");
      swiper.slideTo(index, 1, false);
    });
  });
}

if (windows.length) {
  windows.forEach((window, index) => {
    let speed = 1;
    window.addEventListener("click", () => {
      slider.classList.add("show");
      openPopup();
      if (slider.classList.contains("show")) {
        windowsSection.classList.add("hidden");
      } else {
        windowsSection.classList.remove("hidden");
      }

      main.classList.remove("fixed-height");
      page.style.overflowY = "hidden";
      controls.classList.add("hide");
      buildingSection.classList.add("hide");
      windowsSection.classList.remove("show");
      swiper.slideTo(index, speed, false);
    });
  });
}

if (employeeItems.length) {
  employeeItems.forEach((window, index) => {
    let speed = 1;
    window.addEventListener("click", () => {
      slider.classList.add("show");
      openPopup();
      if (slider.classList.contains("show")) {
        employees.classList.add("hidden");
      } else {
        employees.classList.remove("hidden");
      }

      main.classList.remove("fixed-height");
      page.style.overflowY = "hidden";
      controls.classList.add("hide");
      buildingSection.classList.add("hide");
      employees.classList.remove("show");
      windowsSection.classList.remove("show");
      swiper.slideTo(index, speed, false);
    });
  });
}

if (buildingLinks.length) {
  buildingLinks.forEach((building, index) => {
    console.log("🚀 ~ buildingLinks.forEach ~ building:", building);

    let speed = 1;
    building.addEventListener("click", () => {
      console.log("click");
      // slider.classList.add("show");
      // if (slider.classList.contains("show")) {
      //   employees.classList.add("hidden");
      // } else {
      //   employees.classList.remove("hidden");
      // }

      // main.classList.remove("fixed-height");
      // page.style.overflowY = "hidden";
      // controls.classList.add("hide");
      buildingSection.classList.add("hide");
      // employees.classList.remove("show");
      // windowsSection.classList.remove("show");
      buildingPersons.slideTo(index, speed, false);

      // Проверяем существование перед использованием

      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    });
  });
}

// Обработка закрытия модальных окон
if (modalClose.length) {
  modalClose.forEach((close) => {
    close.addEventListener("click", () => {
      slider?.classList.remove("show");
      closePopup();
      buildingSection?.classList.remove("hide");
      page.style.overflowY = "visible";
      controls?.classList.remove("hide");
      welldone?.classList.remove("show");
      modals.forEach((item) => item.classList.remove("show"));
      windowsSection?.classList.remove("hidden");
    });
  });
}

let fixedScreens = document.querySelectorAll(".screen-fixed");
let screenNight = document.querySelector(".screen__night");
let itemsHeight = 0;

fixedScreens.forEach((item) => {
  itemsHeight += item.offsetHeight;
  screenNight.style.marginTop = itemsHeight + "px";
});

// Логика масштабирования
if (controlsElementZoom && pixel) {
  controlsElementZoom.forEach((item, index, array) => {
    item.addEventListener("click", () => {
      // Проверяем, есть ли класс zoom у элемента pixel
      const zoom = pixel.classList.contains("zoom");

      if (zoom) {
        // Если zoom выключен, удаляем класс active у всех элементов
        array.forEach((el) => el.classList.remove("active"));
      } else {
        // Если zoom включен, добавляем класс active всем элементам
        array.forEach((el) => el.classList.add("active"));
      }

      // Переключаем класс zoom для элемента pixel
      pixel.classList.toggle("zoom");
    });
  });
}

const modalAllButtons = document.querySelectorAll(".modal__all");
const employees = document.querySelector(".employees");
if (modalAllButtons) {
  modalAllButtons.forEach((item, index) => {
    item.addEventListener("click", () => {
      employees.classList.add("show");
    });
  });
}

let controlsElementSound = document.querySelectorAll(".controls__slider-sound");
// Воспроизведение аудио

let isPlaying = false;
let audio = new Audio(); // Инициализируем объект аудио
let currentAudioIndex = null; // Храним текущий индекс аудио

const audioFiles = {
  slidesRu: [
    "https://kasperskyspecial.ru/audio/audio_slide_1_rus.mp3",
    "https://kasperskyspecial.ru/audio/audio_slide_2_rus.mp3",
    "https://kasperskyspecial.ru/audio/audio_slide_3_rus.mp3",
    "https://kasperskyspecial.ru/audio/audio_slide_4_rus.mp3",
    "https://kasperskyspecial.ru/audio/audio_slide_5_rus.mp3",
    "https://kasperskyspecial.ru/audio/audio_slide_6_rus.mp3",
    "https://kasperskyspecial.ru/audio/audio_slide_7_rus.mp3",
    "https://kasperskyspecial.ru/audio/audio_slide_8_rus.mp3",
    "https://kasperskyspecial.ru/audio/audio_slide_9_rus.mp3",
    "https://kasperskyspecial.ru/audio/audio_slide_10_rus.mp3",
    "https://kasperskyspecial.ru/audio/audio_slide_11_rus.mp3",
    "https://kasperskyspecial.ru/audio/audio_slide_12_rus.mp3",
  ],
  slidesEng: [
    "https://kasperskyspecial.com/audio/audio_slide_1_eng.mp3",
    "https://kasperskyspecial.com/audio/audio_slide_2_eng.mp3",
    "https://kasperskyspecial.com/audio/audio_slide_3_eng.mp3",
    "https://kasperskyspecial.com/audio/audio_slide_4_eng.mp3",
    "https://kasperskyspecial.com/audio/audio_slide_5_eng.mp3",
    "https://kasperskyspecial.com/audio/audio_slide_6_eng.mp3",
    "https://kasperskyspecial.com/audio/audio_slide_7_eng.mp3",
    "https://kasperskyspecial.com/audio/audio_slide_8_eng.mp3",
    "https://kasperskyspecial.com/audio/audio_slide_9_eng.mp3",
    "https://kasperskyspecial.com/audio/audio_slide_10_eng.mp3",
    "https://kasperskyspecial.com/audio/audio_slide_11_eng.mp3",
    "https://kasperskyspecial.com/audio/audio_slide_12_eng.mp3",
  ],
};

function playAudio(index, controlElement) {
  if (currentAudioIndex !== index) {
    stopAudio(); // Остановить предыдущее аудио

    if (window.location.href.indexOf("kasperskyspecial.com") > -1) {
      audio.src = audioFiles.slidesEng[index];
    } else {
      audio.src = audioFiles.slidesRu[index];
    }
    currentAudioIndex = index;

    controlElement.classList.add("loading");

    const onCanPlay = () => {
      controlElement.classList.remove("loading");
      audio
        .play()
        .then(() => {
          isPlaying = true;
        })
        .catch((err) => {
          console.error("Error during audio playback:", err);
        });
      audio.removeEventListener("canplaythrough", onCanPlay);
    };

    // Обработчик для завершения аудио
    const onAudioEnd = () => {
      console.log("Аудио завершено");
      controlElement.classList.remove("active");
      isPlaying = false;
      currentAudioIndex = null;
    };

    audio.addEventListener("canplaythrough", onCanPlay);
    audio.addEventListener("ended", onAudioEnd); // Отслеживаем завершение
    audio.load();
  } else {
    // Если индекс совпадает, просто продолжаем воспроизведение
    audio
      .play()
      .then(() => {
        isPlaying = true;
      })
      .catch((err) => {
        console.error("Error during audio playback:", err);
      });
  }
}

function stopAudio() {
  if (audio && !audio.paused) {
    audio.pause();
    audio.currentTime = 0;
  }
  isPlaying = false;
  currentAudioIndex = null;
}

// Добавляем обработчики к кнопкам плей на слайдах
controlsElementSound.forEach((item, index) => {
  item.addEventListener("click", () => {
    if (isPlaying && currentAudioIndex === index) {
      stopAudio();
      item.classList.remove("active");
    } else {
      controlsElementSound.forEach((el) => el.classList.remove("active")); // Снимаем класс с других кнопок
      item.classList.add("active");
      playAudio(index, item); // Передаем элемент управления для обновления класса
    }
  });
});

const scrollStopped = { value: false };
let scrollItems;
let currentScrollIndex = 0; // Индекс текущего блока для скролла
let isScrolling = false; // Флаг для определения активного автоскролла

function isScrollEnd(block) {
  return block.scrollTop + block.clientHeight >= block.scrollHeight - 1; // Точность
}

function shouldStopScrolling() {
  return scrollStopped.value;
}

function scrollBlock(blocks, index) {
  if (shouldStopScrolling() || !blocks[index]) return;

  const currentBlock = blocks[index];
  if (isScrollEnd(currentBlock)) {
    // console.log("End of block, moving to next slide.");
    swiper.slideNext(); // Переключаем на следующий слайд
    currentBlock.scrollTop = 0; // Сбрасываем прокрутку текущего блока

    // Переходим к следующему блоку
    const nextIndex = (index + 1) % blocks.length;
    setTimeout(() => {
      if (!shouldStopScrolling()) scrollBlock(blocks, nextIndex);
    }, 2000); // Пауза перед началом скролла следующего блока
  } else {
    currentBlock.scrollBy(0, 2); // Скроллим текущий блок

    setTimeout(() => {
      if (!shouldStopScrolling()) scrollBlock(blocks, index);
    }, 20); // Задержка для плавного скролла
  }
}

// Инициализация Swiper с добавлением автоскролла
let swiper = new Swiper(".swiper", {
  slidesPerView: 1,
  spaceBetween: 30,
  loop: true,
  // effect: "fade",
  lazy: true,
  grabCursor: true,
  effect: "creative",
  creativeEffect: {
    prev: {
      shadow: true,
      translate: [0, 0, -400],
    },
    next: {
      translate: ["100%", 0, 0],
    },
  },
  // fadeEffect: {
  //   crossFade: true, // Сделает переходы плавными
  // },
  // loopAdditionalSlides: 1,
  navigation: {
    nextEl: ".slider .swiper-button-next",
    prevEl: ".slider .swiper-button-prev",
  },
  pagination: {
    el: ".slider .swiper-pagination",
    clickable: true,
  },
  on: {
    slideChange: function () {
      // Запуск аудио для нового слайда
      if (isPlaying) {
        const controlElement = controlsElementSound[this.realIndex];
        controlsElementSound.forEach((el) => el.classList.remove("active"));
        if (controlElement) {
          controlElement.classList.add("active");
          playAudio(this.realIndex, controlElement); // Передаем кнопку
        }
      }

      // Автоскролл для нового слайда
      // startSliderScroll();
    },
  },
});

// Остановка автоскролла при необходимости
function stopScrolling() {
  scrollStopped.value = true;
  // console.log("Scrolling stopped.");
}

// Перезапуск скролла
function restartScrolling() {
  scrollStopped.value = false;
  startSliderScroll();
}

// Скролл на главной
function toggleSmoothScroll(isScrolling) {
  // let isScrolling = false; // Флаг для отслеживания состояния прокрутки
  let timerId;

  function handleClick() {
    const controlsElementPlay = document.querySelector(
      ".controls__element-play"
    );
    controlsElementPlay.classList.toggle("active");
    if (isScrolling) {
      stopSmoothScroll();
    } else {
      startSmoothScroll();
    }
  }

  function startSmoothScroll() {
    // console.log("🚀 ~ Запуск автоскролла на главной");
    isScrolling = true;
    scrollStopped.value = false; // Сбрасываем флаг остановки
    smoothScroll();
  }

  function stopSmoothScroll() {
    // console.log("🚀 ~ Остановка автоскролла на главной");
    isScrolling = false;
    scrollStopped.value = true; // Устанавливаем флаг остановки
    clearInterval(timerId);
    // controlsElementPlay.forEach((el) => el.classList.remove("active"));
  }

  function smoothScroll() {
    // console.log("🚀 ~ Скролл в процессе на главной");
    timerId = setInterval(() => {
      window.scrollBy(0, 0.5);
      if (
        window.pageYOffset + window.innerHeight >=
        document.body.scrollHeight
      ) {
        // console.log("главная закончилась");
        clearInterval(timerId);

        // stopSmoothScroll();
        startSliderScroll(isScrolling); // Переходим к слайдерам
      }
    }, 10);
  }

  mainElementPlay.addEventListener("click", handleClick);
}

function startSliderScroll() {
  isScrolling = true;
  continueScroll(isScrolling);
}

function continueScroll(isScrolling) {
  let isSliderActive = isScrolling; // Локальный флаг состояния
  let hasRun = false;
  if (hasRun) return;
  if (isSliderActive) {
    const slider = document.querySelector(".slider.modal");
    const sliderPlay = document.querySelector(".controls__slider-play");
    if (slider) {
      openPopup();
      slider.classList.add("show"); // Автоклик для активации слайда
    }

    // Запуск автопрокрутки с небольшой задержкой
    setTimeout(() => {
      sliderPlay.click();
    }, 1000);

    hasRun = true;
  }
}

// Скролл в слайдере
function toggleSmoothScrollSlider(isScrolling) {
  let timerId; // ID для интервала автоскролла

  function handleClick(el) {
    if (isScrolling) {
      stopSmoothScroll();
    } else {
      startSmoothScroll(el);
    }
  }

  function startSmoothScroll(el) {
    isScrolling = true;
    scrollStopped.value = false;
    updateIconsState(); // Обновляем состояние иконок
    smoothScroll(el);
  }

  function stopSmoothScroll() {
    isScrolling = false;
    scrollStopped.value = true;
    clearInterval(timerId);
    updateIconsState(); // Обновляем состояние иконок
  }

  function updateIconsState() {
    sliderItems.forEach((item) => {
      const controlsElementPlay = item.querySelector(".controls__element-play");
      controlsElementPlay.classList.toggle("active", isScrolling);
    });
  }

  function smoothScroll(el) {
    const scrollEl = el.querySelector(".slider__info-wrapper");

    // if (isMobile) {
    //   scrollEl = el.querySelector(".slider__info-wrapper");
    //   // console.log('scrollEl', scrollEl);
    // } else {
    //   scrollEl = el.querySelectorAll(".slider .slider__info-wrapper");
    // }

    function performScroll() {
      // Скроллим текущий блок
      scrollEl.scrollBy(0, 1);

      // Проверяем, достигнут ли конец текущего блока
      if (
        scrollEl.scrollTop + scrollEl.clientHeight >=
        scrollEl.scrollHeight - 1
      ) {
        clearInterval(timerId); // Останавливаем текущий интервал

        // Переключаемся на следующий слайд
        if (swiper) {
          swiper.slideNext(); // Переключаем слайд
        }

        // Ждем анимацию переключения слайда, затем запускаем автоскролл следующего слайда
        setTimeout(() => {
          const nextSlide = el.nextElementSibling; // Получаем следующий слайд
          if (nextSlide) {
            startSmoothScroll(nextSlide); // Начинаем автоскролл следующего слайда
          } else {
            stopSmoothScroll(); // Если слайдов больше нет, останавливаемся
          }
        }, 500); // Убедитесь, что время совпадает с длительностью анимации переключения слайдов
      }
    }

    // Устанавливаем интервал для плавного скролла
    clearInterval(timerId); // Очистка предыдущего интервала (если был)
    timerId = setInterval(performScroll, 16);
  }

  sliderItems.forEach((item, index) => {
    const controlsElementPlay = item.querySelector(".controls__slider-play");

    controlsElementPlay.addEventListener("click", () => {
      handleClick(item);
    });
  });
}

if (contentHidden) {
  contentHidden.forEach((item) => {
    setTimeout(() => {
      item.classList.add("visible");
    }, 1000);
  });
}

function toggleAudio() {
  let controlsElementSound = document.querySelector(".controls__main-sound");
  let isPlaying = false;
  let audio;
  if (window.location.href.indexOf("kasperskyspecial.com") > -1) {
    audio = new Audio("https://kasperskyspecial.com/audio/audio_main_eng.mp3");
  } else {
    audio = new Audio("https://kasperskyspecial.ru/audio/audio_main_rus.mp3");
  }

  function handleClick() {
    if (isPlaying) {
      stopAudio();
    } else {
      playAudio();
    }
  }

  function playAudio() {
    isPlaying = true;
    audio.play();
    controlsElementSound.classList.add("active");
  }

  function stopAudio() {
    isPlaying = false;
    audio.pause();
    controlsElementSound.classList.remove("active");
    // audio.currentTime = 0;
  }

  controlsElementSound.addEventListener("click", handleClick);
}

toggleAudio();
toggleSmoothScroll();
toggleSmoothScrollSlider(isScrolling);
